

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import {api_url, base_url} from "@/ayar";
import TableBaseComponent from "@/components/backoffice/table/TableBaseComponent.vue";
import {onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {l} from "@/core/helpers/lang";

export default ({
  name: "Bayilerim",
  components: {
    TableBaseComponent
  },
  data: () => {
    return {
      url: api_url + 'myReference',
      columns: [
        {
          title: `<div class="form-check form-check-sm form-check-custom form-check-solid me-3 ms-2">
                            <input class="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                        </div>`,
          class: 'w-10px pe-2 ',
          settings: {
            data: 'id',
            orderable: false,
            searchable: false
          }
        },
        {
          title: l('admin:transfer:Sponsor ID'),
          settings: {
            data: 'sponsor_id',
            orderable: true
          }
        },
        {
          title: l('admin:transfer:Adı Soyadı'),
          settings: {
            data: 'name',
            orderable: true
          }
        },
        {
          title: l('admin:transfer:GSM'),
          settings: {
            data: 'phone',
            orderable: true
          }
        },
        {
          title: l('admin:genel:detay'),
          settings: {
            data: 'id',
            orderable: true
          }
        },
      ],
      columnDefs: [
        {
          "render": function (data, type, row) {
            return `<div class="form-check form-check-sm form-check-custom form-check-solid ms-2">
                                <input
                                class="form-check-input widget-13-check"
                                type="checkbox"
                                value="${row['id']}"/>
                            </div>`;
          },
          "targets": 0,
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-black-80 fw-bold d-block fs-7' >" + data + "</div>";
          },
          "targets": 1,
          className: 'text-right align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-black-80 fw-bold d-block fs-7' >" + row.name+' '+row.surname + "</div>";

          },
          "targets": 2,
          className: 'text-left align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-black-80 fw-bold d-block fs-7' >" + data + "</div>";
          },
          "targets": 3,
          className: 'text-right align-bottom'
        },
        {
          "render": function (data, type, row) 
          { return`<a href='javascript:;' data-name='DetailsProfile' data-id='${row.sponsor_id}' class='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1 routerLink' >
                      <i class='far fa-eye'></i>
                    </a>
                    <a href='javascript:;' data-name='Network Ağacım' data-id='${row.sponsor_id}' class='btn btn-icon btn-bg-light btn-color-gray-600 routerLink btn-active-color-warning btn-sm me-1'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-diagram-2-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-3 8A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1z"/>
                      </svg>
                    </a>
                    <a href='javascript:;' data-name='Unilevel Ağacım' data-id='${row.sponsor_id}' class='btn btn-icon btn-bg-light btn-color-gray-600 btn-active-color-danger routerLink btn-sm me-1'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-diagram-3-fill" viewBox="0 0 16 16" >
                        <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z"/>
                      </svg>
                    </a>`;
          },
          "targets": 4

        }
      ],

    }
  },
  methods: {
    drawCallback: function (obj) {
      document.querySelectorAll<HTMLElement>('.routerLink').forEach(element => {
        element.addEventListener("click", e => {
          e.preventDefault();
          obj.$router.push({name: element.dataset.name, params: {id: element.dataset.id}})
        });
      });
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle(l('admin:transfer:Bayilerim'));
    });
    return{l}
  }
});
